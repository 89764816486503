import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import image from "../img/logos/logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { faMapMarker, faUser, faLock, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css";
import "../css/slick.css";
import image1 from "../img/about.jpg"
import image2 from "../img/events/blog-1.jpg"
import banner from "../img/banner/banner-4.jpg";
import banner1 from "../img/banner/banner-3.jpg";
import banner2 from "../img/banner/banner-1.jpg";
import Footer from "../../src/footer/index";
import Header from "../../src/header/index";
import Carousel from 'react-bootstrap/Carousel';
function Index() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [showReadMore, setShowReadMore] = useState(false);
    const [showAllEvents, setShowAllEvents] = useState(false);
    useEffect(() => {
        
        fetchData();
      }, []); // Empty dependency array ensures the effect runs only once
    
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axios.put(
            "http://ncc.riotinfomedia.com:12082/api/v1/event/get",
            {},
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 401 || response.status === 404) {
            // Redirect to the login page for 401 or 404 errors
            // navigate("/login");
            return; // Exit early to prevent further logic execution
          }
          const sortedData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
          setData(sortedData);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };
      const formatDate = (dateString) => {
        const options = { day: "2-digit", month: "short" };
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", options);
      };
      const handleEventClick = (eventid) => {
        localStorage.setItem("clickedEventId", JSON.stringify(eventid));
        // Navigate to the specific event page using the event ID
        navigate(`/Eventdetails/${eventid}`);
      };
      const toggleReadMore = () => {
        setShowReadMore(!showReadMore);
      };
      const [bannerImages, setBannerImages] = useState([]);

  const fetchBannerImages = async () => {
    try {
      setLoading(true);
      const response = await axios.put(
        "http://ncc.riotinfomedia.com:12082/api/v1/banners/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate("/login");
        return; // Exit early to prevent further logic execution
      }
      setBannerImages(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch banner images when the component mounts
  useEffect(() => {
    fetchBannerImages();
  }, []);
return(
        <div>
           
        
        <div class="container-fulid">
        <Header/>
        {/* <!-- Banner start --> */}
      {/* Replace the Bootstrap Carousel rendering */}
{/* With the react-bootstrap Carousel component */}
<Carousel>
  {bannerImages.map((banner, index) => (
    <Carousel.Item key={banner.bannerid}>
      <img
        className="d-block w-100"
        src={banner.file}
        alt={`banner-${index}`}
    //    style={{width:"1903px",height:"530px" }}
      />
      <Carousel.Caption>
        <p>{banner.description}</p>
        <Link to="/Register" className="btn-white">
          Register now
        </Link>
      </Carousel.Caption>
    </Carousel.Item>
  ))}
</Carousel>

{/* <!-- Banner end --> */}

{/* <!-- Featured Properties start --> */}
<div class="featured-properties content-area">
    <div class="container">
        {/* <!-- Main title --> */}
        <div class="row">
        <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <div class="">
                <h1>Wlecome All</h1>
                <p>Nagarathar chamber of cornmerce, shortly known as NCC is formed by a group of young entrepreneurs who are likeminded for a social cause, to bring up the young nagarathars who are finding their future in lucrative jobs and overseas opportunities back to commerce which is the lifeline of Nagarathars.</p>
                <p>Nagarathars, who are part of Vysyas in ancient Indian culture, started their venture into business as salt merchants, moved in groups across the Dravidian landscape and later ventured abroad into countries like Burma, Malaysia, Singapore, Saigon 8, Ceylon, but now the youngsters have shrunk their wings and shied away from business to employment both in India and overseas.</p>
                </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                <div class="about-slider-box simple-slider">
                    <img class="d-block w-100" src={image1} alt="about"/>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- Featured Properties end --> */}

{/* <!-- advantages start --> */}
<div class="advantages content-area">
    <div class="container">
        {/* <!-- Main title --> */}
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="advantages-box">
                    <div class="icon">
                        <FontAwesomeIcon icon= {faMapMarker }/>
                    </div>
                    <div class="detail">
                        <h5>Various Locations</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="advantages-box">
                    <div class="icon">
                        <FontAwesomeIcon icon={faUser}/>
                    </div>
                    <div class="detail">
                        <h5>View Members</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="advantages-box">
                    <div class="icon">
                        <FontAwesomeIcon icon ={faLock}/>
                    </div>
                    <div class="detail">
                        <h5>Privacy and Security</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="advantages-box">
                    <div class="icon">
                        <FontAwesomeIcon icon={faDollarSign}/>
                    </div>
                    <div class="detail">
                        <h5>No Commission</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- advantages end --> */}
{/* <!-- Blog section start --> */}
<div class="blog-section content-area">
    <div class="container">
        {/* <!-- Main title --> */}
        <div class="main-title">
            <h1>Recent Events</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
        </div>
        <Link onClick={() => setShowAllEvents(!showAllEvents)} style={{marginLeft:"94%"}}>
    {showAllEvents ? "Show less" : "View All"}
  </Link>
        {loading ? (
            <div className="text-center">
              <p>Loading...</p>
              {/* You can use a loading spinner here if you have one */}
            </div>
          ) : (
        <div class="row">
             {data
                                         .filter((event) => true)
                                         .slice(0, showAllEvents ? data.length : 5)   
                                        .map((event) => (
            <div class="col-lg-4 col-md-6" style={{height:'600px'}} key={event.eventid}>
                <div class="blog-2" style={{height:"90%"}} onClick={() => handleEventClick(event.eventid)}>
                    <div class="blog-photo">
                        <img  src={event.eventimage} alt="small-blog" class="img-fluid"/>
                        <div class="date-box">
                            <span>{formatDate(event.date)}</span>
                        </div>
                         
                    </div>
                    <div class="detail">
                        
                        <h4>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {event.title}
                        </a>
                        </h4>
                        <p>
                      {showReadMore
                        ? event.description
                        : `${event.description.substring(0, 120)}...`}
                      {event.description.length > 120 && (
                        <span onClick={toggleReadMore} style={{ color: "blue", cursor: "pointer" }}>
                          {showReadMore ? " Read Less" : " Read More"}
                        </span>
                      )}
                    </p>
                    </div>
                </div>
            </div>
             ))}
        </div>
          )}
    </div>
</div>
{/* <!-- Blog section end --> */}

{/* <!-- Intro section start --> */}

{/* <!-- Intro section end --> */}

</div>
<Footer/>
 </div>  
    )    
}
export default Index;        