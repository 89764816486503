import React, { useState, useEffect, useRef  } from "react";
import "../App.css";
import "../css/style.css";
import "../css/bootstrap.min.css";
import "../css/default.css";
import "../css/slick.css";
import Header from "../header";
import Footer from "../footer";
import Image from "../img/events/blog-1.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function EventGallery() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
 

  const fetchEventData = async () => {
    try {
      setLoading(true);
      const clickedEventId = JSON.parse(localStorage.getItem("clickedEventIdforgallery"));

      if (!clickedEventId) {
        console.error("Invalid clickedEventId");
        return;
      }

      const response = await axios.put(
        "http://ncc.riotinfomedia.com:12082/api/v1/event/getbyid",
        { eventid: clickedEventId },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status !== 200) {
        console.error("API error:", response);
        // Handle error, set an error state, etc.
        return;
      }

      setData(response.data);
      localStorage.removeItem("clickedEventIdforgallery");
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error, set an error state, etc.
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEventData();
  }, []);
  return (
    <div>
      <Header />
      <div class="blog-body content-area">
        <div class="container">
          {loading ? (
            <div className="text-center">
              <p>Loading...</p>
              {/* You can use a loading spinner here if you have one */}
            </div>
          ) : (
            <div class="row" >
             {data.files.map((file, index) => (
                <div className="col-lg-4 col-md-6" key={file.fileid}>
                  {/* Adjust the column classes based on your layout preferences */}
                  <img
                    src={file.file}
                    alt={`Event Image ${file.fileid}`}
                    className="img-fluid mb-4"
                  />
                </div>
              ))}
            </div>
          )}

          {/* <!-- Page navigation start --> */}
         
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EventGallery;
