import React from "react";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import { faMapMarker, faEnvelope, faPhone, faFax } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faGooglePlus, faLinkedin, faSkype  } from '@fortawesome/free-brands-svg-icons';
import Image from "../img/avatar/founding_member.jpg";
// import Image2 from "../img/properties/properties-1.jpg";
// import Image3 from "../img/properties/properties-2.jpg";
import Header from "../header";
import Footer from "../footer";
function Member(){
    return(
        <div>
            <Header/>
        
        <div class="container">
<div class="agent-page content-area">
    <div class="container">
        {/* <!-- Heading --> */}
        <h1 class="heading-2">Agent Details</h1>
        <div class="row">
            <div class="col-lg-8">
                <div class="row team-2 mb-50">
                    <div class="col-xl-5 col-lg-6 col-md-5 col-sm-12 col-pad ">
                        <div class="photo">
                            <img src={Image} alt="avatar-9" class="img-fluid"/>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-6 col-md-7 col-sm-12 col-pad align-self-center bg">
                        <div class="detail">
                            <h4>
                                <a href="agent-detail.html">Eliane Pereira</a>
                            </h4>
                            <h5>Creative Director</h5>
                            <div class="contact">
                            <ul>
    <li>
        <FontAwesomeIcon icon={faMapMarker} /> <a>44 New Design Street,</a>
    </li>
    <li>
        <FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:info@themevessel.com">info@themevessel.com</a>
    </li>
    <li>
        <FontAwesomeIcon icon={faPhone} /> <a href="tel:+554XX-634-7071">+55 4XX-634-7071</a>
    </li>
    <li>
        <FontAwesomeIcon icon={faFax} /> <a href="#">+0477 85X6 552</a>
    </li>
    <li>
        <FontAwesomeIcon icon={faSkype} /> <a href="tel:+554XX-634-7071">john.antony</a>
    </li>
    </ul>
                            </div>

                            <ul class="social-list clearfix">
                            <li><a href="#" className="facebook-bg"><FontAwesomeIcon icon={faFacebook} /></a></li>
                        <li><a href="#" className="twitter-bg"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        <li><a href="#" className="google-bg"><FontAwesomeIcon icon={faGooglePlus} /></a></li>
                        <li><a href="#" className="linkedin-bg"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="agent-biography mb-50">
                    <h3 class="heading-2">Biography</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla. Nulla posuere sapien vitae lectus suscipit, et pulvinar nisi tincidunt. Aliquam erat volutpat. Curabitur convallis fringilla diam sed aliquam. Sed tempor iaculis massa faucibus feugiat. In fermentum facilisis massa, a consequat purus viverra a.</p>
                    <p>Ut euismod ultricies sollicitudin. Curabitur sed dapibus nulla. Nulla eget iaculis lectus. Mauris ac maximus neque. Nam in mauris quis libero sodales eleifend. Morbi varius, nulla sit amet rutrum elementum</p>
                    <p>Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla. Aliquam ultricies nunc porta metus interdum mollis. Donec porttitor libero augue, vehicula tincidunt lectus placerat a. Sed tincidunt dolor non sem dictum dignissim. Nulla vulputate orci felis, ac ornare purus ultricies a. Fusce euismod magna orci, sit amet aliquam turpis dignissim ac. </p>
                </div>
                <h3 class="heading-2">Featured Properties</h3>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="property-box">
                            <div class="property-thumbnail">
                                <a href="properties-details.html" class="property-img">
                                    <div class="tag">Featured</div>
                                    <div class="price-box"><span>$850.00</span> month</div>
                                    <img class="d-block w-100" src={Image} alt="properties"/>
                                </a>
                            </div>
                            <div class="detail">
                                <h1 class="title">
                                    <a href="properties-grid-rightside.html">Relaxing Apartment</a>
                                </h1>
                                <div class="location">
                                    <a href="properties-details.html">
                                        <i class="flaticon-pin"></i>123 Kathal St. Tampa City,
                                    </a>
                                </div>
                                <ul class="facilities-list clearfix">
                                    <li>
                                        <i class="flaticon-bed"></i> 3 Beds
                                    </li>
                                    <li>
                                        <i class="flaticon-bathroom"></i> 2 Baths
                                    </li>
                                    <li>
                                        <i class="flaticon-ui"></i> Sq Ft:3400
                                    </li>
                                    <li>
                                        <i class="flaticon-car"></i> 1 Garage
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="property-box">
                            <div class="property-thumbnail">
                                <a href="properties-details.html" class="property-img">
                                    <div class="tag">Featured</div>
                                    <div class="price-box"><span>$850.00</span> month</div>
                                    <img class="d-block w-100" src={Image} alt="properties"/>
                                </a>
                            </div>
                            <div class="detail">
                                <h1 class="title">
                                    <a href="properties-details.html">Relaxing Apartment</a>
                                </h1>
                                <div class="location">
                                    <a href="properties-details.html">
                                        <i class="flaticon-pin"></i>123 Kathal St. Tampa City,
                                    </a>
                                </div>
                                <ul class="facilities-list clearfix">
                                    <li>
                                        <i class="flaticon-bed"></i> 3 Beds
                                    </li>
                                    <li>
                                        <i class="flaticon-bathroom"></i> 2 Baths
                                    </li>
                                    <li>
                                        <i class="flaticon-ui"></i> Sq Ft:3400
                                    </li>
                                    <li>
                                        <i class="flaticon-car"></i> 1 Garage
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="sidebar-right">
                    {/* <!-- Contact 2 start --> */}
                    <div class="contact-2 widget">
                        <h3 class="sidebar-title">Contact Agent</h3>
                        <div class="s-border"></div>
                        <form action="#" method="GET" enctype="multipart/form-data">
                            <div class="rowo">
                                <div class="form-group name">
                                    <input type="text" name="name" class="form-control" placeholder="Name"/>
                                </div>
                                <div class="form-group email">
                                    <input type="email" name="email" class="form-control" placeholder="Email"/>
                                </div>
                                <div class="form-group number">
                                    <input type="text" name="phone" class="form-control" placeholder="Phone"/>
                                </div>
                                <div class="form-group message">
                                    <textarea class="form-control" name="message" placeholder="Write message"></textarea>
                                </div>
                                <div class="send-btn">
                                    <button type="submit" class="btn btn-md button-theme btn-block">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<Footer/>
</div>
    )
}
export default Member;