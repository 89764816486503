import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css";
import "../css/default.css";
import "../css/slick.css";
import Header from "../header";
import Footer from "../footer";
import { Select } from "antd";

const { Option } = Select;

const rowStyle = {
  height: '15.0pt'
};

function List() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const fetchDataforgetbyType = async () => {
    try {
      const response = await axios.post(
        "http://ncc.riotinfomedia.com:12082/api/v1/client/getbytype",
        {
          membertype: "Chamber Member",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            "Content-Type": "application/json",
          },
        }
      );
      const clientIds = response.data.map((item) => item.clientid);
      localStorage.setItem('clientIdsofChamberMember',JSON.stringify(clientIds));
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchCompanyCategories = async () => {
    try {
      const response = await axios.put(
        "http://ncc.riotinfomedia.com:12082/api/v1/companycategory/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setCompanyCategories(response.data);
    } catch (error) {
      console.error("Error fetching company categories:", error);
    }
  };
  useEffect(() => {
    fetchDataforgetbyType();
    fetchCompanyCategories();
    return () => {
      localStorage.removeItem("selectedCategory");
    };
  }, []);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'http://ncc.riotinfomedia.com:12082/api/v1/login/getclientdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        if (response.ok) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);
  useEffect(() => {
    const filteredResults = data.filter((item) =>
      (selectedCategory ? item.companycategory === selectedCategory : true) &&
      (item.idno.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.companyname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.companycategory.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.address1.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setFilteredData(filteredResults);
  }, [searchQuery, data, selectedCategory]);
  
  const handleRowClick = async (item) => {
    try {
      // Assuming item.id is the memberid you want to pass
      const clientid = item.clientid;
  localStorage.setItem('clickedClientId',clientid);
      // Navigate to the UserProfile page or handle the response accordingly
      navigate(`/UserProfile`);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };
  return (
    <div>
      <Header />

      <div className="elements-page content-area">
        <div className="container">
        <div class="main-title">
            <h1>Chamber Member</h1>
        </div>
          {loading ? (
            <p>Loading...</p>
          ) : !isAuthenticated ? (
            <div className="row d-flex flex-column align-items-center justify-content-center">
              
              <p  className="text-center">Please login to view the member details</p>
              <Link to="/Login" className="btn-md button-theme" style={{width:"10%",textAlign:"center"}}>
                Login
              </Link>
            </div>
          ) : (
            
            <div className="row">
               <div className="" style={{marginLeft:"65%"}}>
                <Select
            style={{ width: 200, marginBottom: 16 }}
            placeholder="Select Company Category"
            onChange={(value) => setSelectedCategory(value)}
          >
            {companyCategories.map((category) => (
              <Option key={category.companycategoryid} value={category.companycategory}>
                {category.companycategory}
              </Option>
            ))}
          </Select>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{marginLeft:"30px"}}
            />
          </div>  
              <table
                id="example"
                className="table table-striped table-bordered nowrap"
                style={{ width: '100%' }}
              >
                <thead>
                  <tr>
                    <th>Member ID</th>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Category</th>
                    <th>Contact</th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item) => (
                    <tr key={item.id} height={20} style={{ height: "15.0px" }}  onClick={() => handleRowClick(item)}>
                      <td height={20} style={{ height: "15.0pt" }}>{item.idno}</td>
                      <td>{item.firstname}</td>
                      <td>{item.companyname}</td>
                      <td>{item.companycategory}</td>
                      <td>{item.mobile}</td>
                      <td>{item.address1}</td>
                      <td><Link to={`/UserProfile`}>View</Link></td>
                    </tr>
                  ))}
                </tbody>

                <tfoot>
                  <tr>
                    <th>Member ID</th>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Category</th>
                    <th>Contact</th>
                    <th>Address</th>
                      <th>Action</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default List;
