import React, { useState, useEffect  } from "react";
import '../App.css';
import "../css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/default.css";
import "../css/slick.css";
import Image from "../img/current_memenber/C.Sabari Shankar - EC Member.jpg";
import Header from "../../src/header/index";
import Footer from "../../src/footer/index";
import axios from "axios";
import { Select, Upload, Button, Form, message } from 'antd';
import { useNavigate } from "react-router-dom";
import { UploadOutlined  } from '@ant-design/icons';
import noImage from "../img/noimage.jpg";

function ProfileImage() {
    const navigate = useNavigate();
    const [fileList, setFileList] = useState([]); // To store uploaded files
    const [clientDetails, setClientDetails] = useState(null); // To store client details
    const [userData, setUserData] = useState(null);

    const getClientDetails = async () => {
      try {
          const response = await axios.post(
              "http://ncc.riotinfomedia.com:12082/api/v1/login/getclientdetails",
              {},
              {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                      'Content-Type': 'application/json',
                  },
              }
          );
  
          if (response.status === 401 || response.status === 404) {
              // Redirect to the login page for 401 or 404 errors
              navigate('/login');
              return; // Exit early to prevent further logic execution
          }
  
          // Handle the response data as needed
          const idFromResponse = response.data.id;
          if(idFromResponse!=null){
            const fetchUserDetails = async () => {
                try {
                  const response = await axios.post(
                    "http://ncc.riotinfomedia.com:12082/api/v1/client/getbyid",
                    { clientid: parseInt(idFromResponse )},
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        "Content-Type": "application/json",
                      },
                    }
                  );
        
                  // Set the user data in state
                  setUserData(response.data);
                } catch (error) {
                  console.error("Error fetching user details:", error);
                }
              };
        
              fetchUserDetails();
          }
          // Store the id in localStorage
          localStorage.setItem('clientid', idFromResponse);
  
          // Set the id in the state
          setClientDetails(response.data);
      } catch (error) {
          console.error("Error fetching client details:", error);
      }
  };
  

    useEffect(() => {
        // Fetch client details when the component mounts
        getClientDetails();
    }, []);
    const handleFileUpload = async (options) => {
      const { onSuccess, onError, file, onProgress } = options;
      const formData = new FormData();
      formData.append("file", file);
  
      try {
          const response = await axios.post(
              "http://ncc.riotinfomedia.com:12082/api/v1/fileupload/file",
              formData,
              {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                      'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for file uploads
                  },
                  onUploadProgress: (progressEvent) => {
                      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                      onProgress({ percent: percentCompleted });
                  },
              }
          );
  
          if (response.status === 401 || response.status === 404) {
              // Redirect to the login page for 401 or 404 errors
              navigate('/login');
              return; // Exit early to prevent further logic execution
          }
  
          // Check if the response contains the 'organiserId'
          if (response.data && response.data.organiserId) {
              const organiserId = response.data.organiserId;
  
              // Store the updated organiserIds array back in local storage
              localStorage.setItem('organiserId', organiserId);
          }
  
          localStorage.removeItem('organiserIds');
          message.success("File uploaded successfully");
          onSuccess("File uploaded");
  
          // After successful file upload, make a subsequent API call
          
          const editProfileImageResponse = await axios.post(
              "http://ncc.riotinfomedia.com:12082/api/v1/client/editprofileimage",
              {
                clientid:localStorage.getItem('clientid'), 
                fileid:localStorage.getItem('organiserId'),
              },
              {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                      'Content-Type': 'application/json',
                  },
              }
          );if (editProfileImageResponse.status === 200) {
      // Reload the page to reflect the changes
      window.location.reload();
    }
  
          // Handle the response of the editprofileimage API call as needed
          console.log("Edit Profile Image Response:", editProfileImageResponse.data);
      } catch (error) {
          message.error("File upload failed");
          onError("File upload failed");
      }
  };
return(
        <div>        
        <Header/>
    <div class="our-team content-area">
    <div class="container">
<div class="dashboard-list" >
    <h3 class="main-title">Personal Details</h3>
    <div class="dashboard-message contact-2 bdr clearfix">
        <div class="row">
            <div class="col-lg-3 col-md-3">
               <div class="edit-profile-photo">
                    <img src={(userData?.profilepic) || {noImage}} alt="profile-photo" class="img-fluid"/>
                    <Form.Item
                name="uploadfile"
            >
                <Upload
                customRequest={handleFileUpload} 
                fileList={fileList}
                onChange={({ fileList }) => setFileList(fileList)}
                >
    {/* <Button className="photoUpload" icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Upload Photo</Button> */}
  </Upload>
            </Form.Item> 
                </div> 
            </div>
            <div class="col-lg-9 col-md-9">
                <form action="#" enctype="multipart/form-data"
                >
                    <div class="row">
                    <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Your Name</label>
                                <input type="text" name="name" class="form-control"  value={(userData?.firstname) || ""} />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Last Name</label>
                                <input type="text" name="name" class="form-control"  value={(userData?.lastname) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group">
                                <label>Gender</label>
                                <input type="text" name="name" class="form-control"  value={(userData?.gender) || ""}/>
                            </div>
                            
                        </div>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Marital  Status </label>
                                <input type="text" name="name" class="form-control"  value={(userData?.maritalstatus) || ""}/>
                            </div>
                        </div> 
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group subject">
                                <label>DOB</label>
                                <input type="date" name="COMPANY NAME" class="form-control"  value={(userData?.dateofbirth) || ""} />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Address 1</label>
                                <input type="text" name="text" class="form-control"  value={(userData?.address1) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Country</label>
                                <input type="text" name="text" class="form-control"  value={(userData?.country) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>State</label>
                                <input type="text" name="text" class="form-control"  value={(userData?.state) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>City</label>
                                <input type="text" name="text" class="form-control"  value={(userData?.city) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Area</label>
                                <input type="text" name="text" class="form-control"  value={(userData?.area) || ""}/>
                        </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Pincode</label>
                                <input type="text" name="text" class="form-control"  value={(userData?.pincode) || ""}/>
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Native</label>
                                <input type="text" name="name" class="form-control"   value={(userData?.nativee) || ""}/>
                            </div>
                        </div>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Kovil</label>
                                <input type="text" name="name" class="form-control"   value={(userData?.kovil) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
  <label>Perivu</label>
  <input type="text" name="name" class="form-control"   value={(userData?.perivu) || ""}/>
</div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Mobile</label>
                                <input type="tel" name="text" className="form-control"  value={(userData?.mobile) || ""}/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Email</label>
                                <input type="email" name="email" class="form-control" value={(userData?.email) || ""} />
                                
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Proposer Name</label>
                                <input type="text" name="text" class="form-control" value={(userData?.proposername) || ""}/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>secordorname</label>
                                <input type="text" name="text" class="form-control" value={(userData?.secordorname) || ""} />
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group subject">
                                <label>Year of Joining</label>
                                <input type="text" name="COMPANY NAME" class="form-control" value={(userData?.yearofjoining) || ""}/>
                            </div>
                        </div>
                            <div>
                        <h3 class="main-title">Spouse  Details</h3>
                        <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Name</label>
                                <input type="text" name="text" class="form-control" value={(userData?.spousename) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Native</label>
                                <input type="text" name="text" class="form-control" value={(userData?.spousenativee) || ""}/>
                            </div>
                        </div>
                        
                          <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Kovil</label>
                                <input type="text" name="text" class="form-control" value={(userData?.spousekovil) || ""}/>
                            </div>
                        </div> 

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Perivu</label>
                                <input type="text" name="text" class="form-control" value={(userData?.spouseperivu) || ""}/>
                            </div>
                        </div> 
                        </div>
                        </div>
                        <h3 class="main-title">Father  Details</h3>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Name</label>
                                <input type="text" name="text" class="form-control" value={(userData?.fathersname) || ""} />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Native</label>
                                <input type="text" name="text" class="form-control" value={(userData?.fathersnativee) || ""} />
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Kovil</label>
                                <input type="text" name="text" class="form-control" value={(userData?.fatherskovil) || ""} />
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Perivu</label>
                                <input type="text" name="text" class="form-control" value={(userData?.fathersperivu) || ""} />
                            </div>
                        </div> 
                        <h3 class="main-title">Business  Details</h3>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Company Name</label>
                                <input type="text" name="text" class="form-control" value={(userData?.companyname) || ""} />
                         </div>
                        </div>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Category</label>
                             <input type="text" name="text" class="form-control" value={(userData?.companycategory) || ""} /> 
                        
                         </div>
                        </div>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Landline</label>
                                <input type="text" name="text" class="form-control" value={(userData?.companylandline) || ""}/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Mobile</label>
                                <input type="text" name="text" className="form-control" value={(userData?.companymobile) || ""}/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Email</label>
                                <input type="email" name="email" class="form-control" value={(userData?.companyemail) || ""}/>
                              
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Website Address</label>
                                <input type="text" name="text" class="form-control" value={(userData?.companywebsite) || ""}/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Address 1</label>
                                <input type="text" name="text" class="form-control" value={(userData?.companyaddress1) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Country</label>
                                <input type="text" name="text" class="form-control" value={(userData?.companycountry) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>State</label>
                                <input type="text" name="text" class="form-control" value={(userData?.companystate) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>City</label>
                                <input type="text" name="text" class="form-control" value={(userData?.companycity) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Area</label>
                                <input type="text" name="text" class="form-control" value={(userData?.companyarea) || ""}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Pincode</label>
                                <input type="text" name="text" class="form-control" value={(userData?.companypincode) || ""}/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
       
           
            
       
    </div>
</div>
</div>


</div>

<Footer/>
</div>

    )
}
export default ProfileImage;