import React, { useState, useEffect } from "react";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faGooglePlus, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Image from "../img/current_memenber/Mr.Thirupathi - Mentor.png";
import Image2 from "../img/current_memenber/Muthu Kannan - President.jpeg";
import Image3 from "../img/current_memenber/Uma Meiyappan - Secretary.jpeg";
import Image4 from "../img/current_memenber/Lakshmanan VR - Treasurer.jpg";
import Image5 from "../img/current_memenber/N.Subramanian  - Ec Member.jpg";
import Image6 from "../img/current_memenber/VT.Chandhrasekharan - EC Member.jpg";
import Image7 from "../img/current_memenber/C.Sabari Shankar - EC Member.jpg";
import Header from "../../src/header/index";
import Footer from "../../src/footer/index";
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import noImage from "../img/noimage.jpg";

function Founding() {
    const [data, setData] = useState([]);
    const [ecdata, setDataec] = useState([]);
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [companyCategories, setCompanyCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
  
    const fetchDataforgetbyType = async () => {
      try {
        const response = await axios.post(
          "http://ncc.riotinfomedia.com:12082/api/v1/client/getbytype",
          {
            membertype: "Founding EC Member",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              "Content-Type": "application/json",
            },
          }
        );
        const clientIds = response.data.map((item) => item.clientid);
        localStorage.setItem('clientIdsofAssociateMember',JSON.stringify(clientIds));
        setData(response.data);
        setFilteredData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchCompanyCategories = async () => {
      try {
        const response = await axios.put(
          "http://ncc.riotinfomedia.com:12082/api/v1/companycategory/get",
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        setCompanyCategories(response.data);
      } catch (error) {
        console.error("Error fetching company categories:", error);
      }
    };
    useEffect(() => {
      fetchDataforgetbyType();
      fetchCompanyCategories();
      return () => {
        localStorage.removeItem("selectedCategory");
      };
    }, []);
  
    useEffect(() => {
      const fetchClientDetails = async () => {
        try {
          const response = await fetch(
            'http://ncc.riotinfomedia.com:12082/api/v1/login/getclientdetails',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
  
          if (response.ok) {
            setIsAuthenticated(true);
          }
        } catch (error) {
          console.error("Error checking authentication:", error);
        }
      };
  
      fetchClientDetails();
    }, []);
    useEffect(() => {
      const filteredResults = data.filter((item) =>
        (selectedCategory ? item.companycategory === selectedCategory : true) &&
        (item.idno.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.companyname.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.companycategory.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.address1.toLowerCase().includes(searchQuery.toLowerCase()))
      );
      setFilteredData(filteredResults);
    }, [searchQuery, data, selectedCategory]);
    
    const handleRowClick = async (item) => {
      try {
        // Assuming item.id is the memberid you want to pass
        const clientid = item.clientid;
    localStorage.setItem('clickedClientId',clientid);
        // Navigate to the UserProfile page or handle the response accordingly
        navigate(`/UserProfile`);
      } catch (error) {
        console.error("Error fetching member details:", error);
      }
    };

    const fetchCompanyEcMember = async () => {
      try {
        const response = await axios.post(
          "http://ncc.riotinfomedia.com:12082/api/v1/ecmember/get",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
  
        setDataec(response.data);
      } catch (error) {
        console.error("Error fetching company categories:", error);
      }
    };
    useEffect(() => {
      fetchCompanyEcMember();
      return () => {
        localStorage.removeItem("selectedCategory");
      };
    }, []);
return(
        <div>
            <Header/>
        
        
        <div class="our-team content-area">
    <div class="container">
      
        <div class="main-title">
            <h1>EC Member History</h1>
        </div>
        {loading ? (
            <p>Loading...</p>
          ) : !isAuthenticated ? (
            <div className="row d-flex flex-column align-items-center justify-content-center">
              
              <p  className="text-center">Please login to view the member details</p>
              <Link to="/Login" className="btn-md button-theme" style={{width:"10%",textAlign:"center"}}>
                Login
              </Link>
            </div>
          ) : (
        <div class="row  d-flex justify-content-center">
        {ecdata.map((item, index) => (
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div class="team-1">
                    <div class="team-photo">
                        <a href="#">
                            <img src={item.clientdetail.profilepic || noImage} alt="agent"  />
                        </a>
                    </div>
                    <div class="team-details text-center">
                        <h5><a href="#">{item.clientdetail.firstname}.{item.clientdetail.lastname} </a></h5>
                        <h6>{item.clientdetail.city}</h6>
                        <p>{item.clientdetail.companyname}<br/>
                                {item.clientdetail.companycategory}</p>
                    </div>
                </div>
            </div>
        ))}
        </div>
          )}
    </div>
</div>
<Footer/>
</div>
    )
}
export default Founding;    