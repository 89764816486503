import React,{useState, useEffect } from "react";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import Header from "../header";
import { Select, Upload, Button, Form, message } from 'antd';
import Image from "../img/avatar/founding_member.jpg"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../footer";
import { UploadOutlined  } from '@ant-design/icons';
const { Option } = Select;

function Register() {
  
  const navigate = useNavigate();
    const memberTypes = [
        { membertypeid: 1, membertype: 'Type 1' },
        { membertypeid: 2, membertype: 'Type 2' },
        { membertypeid: 3, membertype: 'Type 3' },
      ];
    
      const [selectedMemberType, setSelectedMemberType] = useState([]);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [password, setPassword] = useState("");
    const [gender, setGender] = useState("");
    const [maritalstatus, setMaritalStatus] = useState('');
    const [dateofbirth, setDateofbirth] = useState("");
    const [address1, setAddress1] = useState("");
    const [city, setCity] = useState("");
    const [area, setArea] = useState("");
    const [nativee, setNative] = useState("");
    const [kovil, setKovil] = useState('');
    const [perivu, setPerivu] = useState([]);
    const [pincode, setPincode] = useState(null);
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [spousename, setSpousename] = useState("");
    const [spousenativee, setSpousenativee] = useState("");
    const [spousekovil, setSpousekovil] = useState("");
    const [spouseperivu, setSpouseperivu] = useState([]);
    const [fathername, setFathername] = useState("");
    const [fathernativee, setFathernativee] = useState("");
    const [fatherkovil, setFatherkovil] = useState("");
    const [fatherperivu, setFatherperivu] = useState([]);
    const [companyname, setCompanyname] = useState("");
    const [companycategory, setCompanycategory] = useState("");
    const [companylandline, setCompanylandline] = useState("");
    const [companymobile, setCompanymobile] = useState("");
    const [companyemail, setCompanyemail] = useState("");
    const [companywebsite, setCompanywebsite] = useState("");
    const [companyaddress1, setCompanyaddress1] = useState("");
    const [companycountry, setCompanycountry] = useState("");
    const [companystate, setCompanystate] = useState("");
    const [companycity, setCompanycity] = useState("");
    const [companyarea, setCompanyarea] = useState("");
    const [companypincode, setCompanypincode] = useState(null);
    const [referedby, setReferedby] = useState([])
    const [membertype, setMembertype] = useState([]);
    const [dateofjoining, setDateofjoining] = useState("");
    const [expirationdate, setExpirationdate] = useState("");
    const [designation, setDesignation] = useState("");
    const [status, setStatus] = useState("");
    const [paymentstatus, setPaymentstatus] = useState("");
    const [selectedPerivu, setSelectedPerivu] = useState("");
    const [selectedPerivuforspouse, setSelectedPerivuforspouse] = useState("");
    const [selectedPerivuforfather, setSelectedPerivuforfather] = useState("");
    const [proposername, setProposername] = useState("");
    const [secordorname, setSecordorname] = useState("");
    const [yearofjoining, setYearofjoining] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidEmailforcomapny, setIsValidEmailforcomapny] = useState(true);
	  const [data, setData] = useState([]);
    const [fileList, setFileList] = useState([]); // To store uploaded files
    const [userData, setUserData] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [pincodeList, setPincodeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [companycountryList, setCompanyCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [companystateList, setCompanyStateList] = useState([]);
    const [companypincodeList, setCompanyPincodeList] = useState([]);
    const [selectedCountryforcompany, setSelectedCountryforcompany] = useState("");
    const [selectedStateforcompany, setSelectedStateforcompany] = useState("");
    const handleEmailChange = (event) => {
      const newEmail = event.target.value;
      setEmail(newEmail);
  
      // Regular expression for basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(newEmail);
      setIsValidEmail(isValid);
    };
    const handleEmailChangeforcompany = (event) => {
      const newEmail = event.target.value;
      setCompanyemail(newEmail);
  
      // Regular expression for basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(newEmail);
      setIsValidEmailforcomapny(isValid);
    };
  const handleMaritalStatusChange = (e) => {
    setMaritalStatus(e.target.value);
  };
  const handleKovilChange = (e) => {
    const selectedKovilValue = e.target.value;
    setKovil(selectedKovilValue);

    // Set perivuOptions based on selected Kovil
    switch (selectedKovilValue) {
      case 'Vairavan Kovil':
        setPerivu([" Sirukulattur", "Kalanivasal", "Maruttendrapuram"]);
        break;
      case 'Ilayathakudi':
        setPerivu(["Okkur", "Arumburkolar (alias Pattanasamy)", "Perumarudur", "Kinkanikkur", "Kalanivasal", "Perasandur", "Sirusettur"]);
        break;
        case 'Mathur':
            setPerivu(["Uraiyur", "Arumbakkur", " Manalur", "Mannur", "Kannur", "Karuppur", "Kulattur"]);
        break;
      default:
        setPerivu([]);
    }
  };
  const handlePerivuChange = (e) => {
    setSelectedPerivu(e.target.value);
  };
  const handleKovilChangeforspouse = (e) => {
    const selectedKovilValueforspouse = e.target.value;
    setSpousekovil(selectedKovilValueforspouse);

    // Set perivuOptions based on selected Kovil
    switch (selectedKovilValueforspouse) {
      case 'Vairavan Kovil':
        setSpouseperivu([" Sirukulattur", "Kalanivasal", "Maruttendrapuram"]);
        break;
      case 'Ilayathakudi':
        setSpouseperivu(["Okkur", "Arumburkolar (alias Pattanasamy)", "Perumarudur", "Kinkanikkur", "Kalanivasal", "Perasandur", "Sirusettur"]);
        break;
        case 'Mathur':
            setSpouseperivu(["Uraiyur", "Arumbakkur", " Manalur", "Mannur", "Kannur", "Karuppur", "Kulattur"]);
        break;
      default:
        setSpouseperivu([]);
    }
  };
  const handlePerivuChangeforspouse = (e) => {
    setSelectedPerivuforspouse(e.target.value);
  };
  const handleKovilChangeforfather = (e) => {
    const selectedKovilValueforfather = e.target.value;
    setFatherkovil(selectedKovilValueforfather);

    // Set perivuOptions based on selected Kovil
    switch (selectedKovilValueforfather) {
      case 'Vairavan Kovil':
        setFatherperivu([" Sirukulattur", "Kalanivasal", "Maruttendrapuram"]);
        break;
      case 'Ilayathakudi':
        setFatherperivu(["Okkur", "Arumburkolar (alias Pattanasamy)", "Perumarudur", "Kinkanikkur", "Kalanivasal", "Perasandur", "Sirusettur"]);
        break;
        case 'Mathur':
            setFatherperivu(["Uraiyur", "Arumbakkur", " Manalur", "Mannur", "Kannur", "Karuppur", "Kulattur"]);
        break;
      default:
        setFatherperivu([]);
    }
  };
  const handlePerivuChangeforfather = (e) => {
    setSelectedPerivuforfather(e.target.value);
  };
  const handleMobileChangeLogin = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) && newValue.length <= 10) {
      setMobile(newValue);
    }
  };
  const handleMobileChangeLoginforComapny = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) && newValue.length <= 10) {
      setCompanymobile(newValue);
    }
  };
  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await fetch(
          "http://ncc.riotinfomedia.com:12082/api/v1/pincode/getcountry",
          {
            method: "PUT",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
          setCompanyCountryList(data);
        } else {
          console.error("Failed to get the list of countries");
        }
      } catch (error) {
        console.error("Error fetching the list of countries:", error);
      }
    };

    fetchCountryList();
  }, []);
  useEffect(() => {
    const fetchCountryListforCompany = async () => {
      try {
        const response = await fetch(
          "http://ncc.riotinfomedia.com:12082/api/v1/pincode/getcountry",
          {
            method: "PUT",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCompanyCountryList(data);
        } else {
          console.error("Failed to get the list of countries");
        }
      } catch (error) {
        console.error("Error fetching the list of countries:", error);
      }
    };

    fetchCountryListforCompany();
  }, []);
  const fetchStates = async (selectedCountry) => {
    try {
      const response = await axios.put(
        "http://ncc.riotinfomedia.com:12082/api/v1/pincode/getstate",
        { country : selectedCountry },
        {
          headers: {  
            "Content-Type": "application/json",
          },
        }
      );

      // Set the fetched states in state
      if (response.status === 200) {
        setStateList(response.data);
        setCompanyStateList(response.data);
      } else {
        console.error("Failed to get the list of states");
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const fetchStatesforCompany = async (selectedCountryforcompany) => {
    try {
      const response = await axios.put(
        "http://ncc.riotinfomedia.com:12082/api/v1/pincode/getstate",
        { country : selectedCountryforcompany },
        {
          headers: {  
            "Content-Type": "application/json",
          },
        }
      );

      // Set the fetched states in state
      if (response.status === 200) {
        setCompanyStateList(response.data);
      } else {
        console.error("Failed to get the list of states");
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const fetchPincodes = async (selectedCountry, selectedState) => {
    try {
      const response = await axios.put(
        "http://ncc.riotinfomedia.com:12082/api/v1/pincode/getpincode",
        {
          country: selectedCountry,
          state: selectedState,
        }
      );

      if (response.status === 200) {
        setPincodeList(response.data);
      } else {
        console.error("Failed to get the list of pincodes");
      }
    } catch (error) {
      console.error("Error fetching the list of pincodes:", error);
    }
  };
  const fetchPincodesforCompany = async (selectedCountryforcompany, selectedStateforcompany) => {
    try {
      const response = await axios.put(
        "http://ncc.riotinfomedia.com:12082/api/v1/pincode/getpincode",
        {
          country: selectedCountryforcompany,
          state: selectedStateforcompany,
        }
      );

      if (response.status === 200) {
        setCompanyPincodeList(response.data);
      } else {
        console.error("Failed to get the list of pincodes");
      }
    } catch (error) {
      console.error("Error fetching the list of pincodes:", error);
    }
  };
  const handleCountryClick = (selectedValue) => {
    console.log("Selected Country:", selectedValue);
    // You can use the selectedValue as the selected country here
    setSelectedCountry(selectedValue);

    // Make the API call to fetch states based on the selected country
    if (selectedValue) {
      fetchStates(selectedValue);
    }
  };
  const handleCountryClickforCompany = (selectedValueforcompany) => {
    console.log("Selected Country:", selectedValueforcompany);
    // You can use the selectedValue as the selected country here
    setSelectedCountryforcompany(selectedValueforcompany);

    // Make the API call to fetch states based on the selected country
    if (selectedValueforcompany) {
      fetchStatesforCompany(selectedValueforcompany);
    }
  };
  const handleStateClick = (selectedValue1) => {
    // You can use the selectedValue as the selected country here
    setSelectedState(selectedValue1);

    // Make the API call to fetch states based on the selected country
    if (selectedValue1) {
      fetchPincodes(selectedCountry, selectedValue1);
    }
  };
  const handleStateClickforCompany = (selectedValueforcompany1) => {
    // You can use the selectedValue as the selected country here
    setSelectedStateforcompany(selectedValueforcompany1);

    // Make the API call to fetch states based on the selected country
    if (selectedValueforcompany1) {
      fetchPincodesforCompany(selectedCountryforcompany, selectedValueforcompany1);
    }
  };
  const handleFileUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);

    try {
        const response = await axios.put(
            "http://ncc.riotinfomedia.com:12082/api/v1/fileupload/filev",
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for file uploads
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress({ percent: percentCompleted });
                },
            }
        );

        if (response.status === 401 || response.status === 404) {
            // Redirect to the login page for 401 or 404 errors
            navigate('/login');
            return; // Exit early to prevent further logic execution
        }

        // Check if the response contains the 'organiserId'
        if (response.data && response.data.organiserId) {
            const organiserId = response.data.organiserId;

            // Store the updated organiserIds array back in local storage
            localStorage.setItem('organiserId', organiserId);
        }

        localStorage.removeItem('organiserIds');
        message.success("File uploaded successfully");
        onSuccess("File uploaded");

        // After successful file upload, make a subsequent API call
      
    } catch (error) {
        message.error("File upload failed");
        onError("File upload failed");
    }
};
  const handleRegister = async (e) => {
    e.preventDefault();
    if (selectedMemberType.length !== 2) {
      // Show a message indicating that two referbys should be selected
      alert('Please select two referbys.');
      return;
    }
    try {
      const organiserId = JSON.parse(localStorage.getItem('organiserId'));
      const selectedClientIds = referedby
      .filter((option) => selectedMemberType.includes(option.name))
      .map((option) => option.clientidid);
        // Register the new client
        const registerResponse = await fetch('http://ncc.riotinfomedia.com:12082/api/v1/login/registerclient', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                password,
                firstname,
                lastname,
                gender,
                maritalstatus,
                dateofbirth,
                address1,
                country : selectedCountry,
                state : selectedState,
                city,
                area,
                nativee,
                kovil:kovil,
                perivu:selectedPerivu,
                pincode: pincode,
                mobile,
                spousename,
                spousenativee,
                spousekovil,
                spouseperivu:selectedPerivuforspouse,
                fathername,
                fathernativee,
                fatherkovil,
                fatherperivu:selectedPerivuforfather,
                companyname,
                companycategory,
                companylandline,
                companymobile,
                companyemail,
                companywebsite,
                companyaddress1,
                companycountry : selectedCountryforcompany ,
                companystate : selectedStateforcompany,
                companycity,
                companyarea,
                companypincode,
                referedby: selectedClientIds,
                membertype,
                dateofjoining,
                expirationdate,
                designation,
                status,
                paymentstatus,
                proposername,
                yearofjoining,
                secordorname,
                uploadfile: organiserId,
            }),
        });

        if (registerResponse.ok) {
            // Registration successful, handle success here
            console.log('Client registration successful');
            localStorage.removeItem(organiserId);
            navigate("/Successful")
        } else {
            // Registration failed, handle error
            console.error('Client registration failed');
        }
    } catch (error) {
        console.error('Error registering client:', error);
    }
};

  useEffect(() => {
    const fetchDataforreferedby = async () => {
      try {
        const response = await fetch(
          "http://ncc.riotinfomedia.com:12082/api/v1/client/getreference",{
            method : 'put',
            headers: {
                'Content-Type': 'application/json',
              },
          }
        );
        if (response.ok) {
          const data = await response.json();
          const referedByNames = data.map((client) => ({
            clientid: client.clientid,
            name: client.name ||  "Unknown",
            companyname: client.companyname || "Unknown"
          }));
          setReferedby(referedByNames.filter(Boolean)); 
        } else {
          console.error("Failed to fetch data from the API");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataforreferedby(); 
  }, []);
  useEffect(() => {
    const fetchDataforreferedby = async () => {
      try {
        const response = await fetch(
          "http://ncc.riotinfomedia.com:12082/api/v1/client/getreference",{
            method : 'put',
            headers: {
                'Content-Type': 'application/json',
              },
          }
        );
        if (response.ok) {
          const data = await response.json();
          const referedByNames = data.map((client) => ({
            clientidid: client.clientid,
            name: client.name || "Unknown",
            companyname: client.companyname || "Unknown"
          }));
          setReferedby(referedByNames.filter(Boolean)); 
        } else {
          console.error("Failed to fetch data from the API");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataforreferedby(); 
  }, []);
  const handleSelectChange = (selectedValues) => {
    if (selectedValues.length > 2) {
      return;
    }

    setSelectedMemberType(selectedValues);
  };
  const fetchDataforcategory = async () => {
		try {
		  const response = await axios.put("http://ncc.riotinfomedia.com:12082/api/v1/companycategory/get",{},{
			
		  headers: {
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			navigate('/login');
			return; 
		  }
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };

    useEffect(() => {
      fetchDataforcategory();
      }, []); 
    return(
        <div>
            <Header/>
        
<div class="container">
<div class="dashboard-list" >
    <h3 class="main-title">Personal Details</h3>
    <div class="dashboard-message contact-2 bdr clearfix">
        <div class="row">
            <div class="col-lg-3 col-md-3">
              <div class="edit-profile-photo">
                    <img src={Image} alt="profile-photo" class="img-fluid"/>
                    <Form.Item
                name="uploadfile"
            >
                <Upload
                customRequest={handleFileUpload} 
                fileList={fileList}
                onChange={({ fileList }) => setFileList(fileList)}
                >
    <Button className="photoUpload" icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Upload Photo</Button>
  </Upload>
            </Form.Item> 
                </div> 
            </div>
            <div class="col-lg-9 col-md-9">
                <form action="#" enctype="multipart/form-data" onSubmit={handleRegister}
                >
                    <div class="row">
                    <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Your Name</label>
                                <input type="text" name="name" class="form-control" placeholder="Enter" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Last Name</label>
                                <input type="text" name="name" class="form-control" placeholder="Enter" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group">
                                <label>Gender</label>
                                <label class=" custom-select-select"/>
                                    <select  name='options'value={gender} onChange={(e) => setGender(e.target.value)} >
                                    <option>select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Fe-male</option>
                                </select>
                            </div>
                            
                        </div>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Marital  Status </label>
                                <label class="custom-select-select"/>
                                    <select  name='options' onChange={handleMaritalStatusChange}>
                                    <option value="single">Single</option>
                                    <option value="married">Married</option>
                                    <option value="widowed">Widowed</option>
                                    <option value="divorced">Divorced</option>
                                </select>
                            
                            </div>
                        </div> 
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group subject">
                                <label>DOB</label>
                                <input type="date" name="COMPANY NAME" class="form-control" placeholder="Enter" value={dateofbirth} onChange={(e) => setDateofbirth(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Address 1</label>
                                <input type="text" name="text" class="form-control" placeholder="Place" value={address1} onChange={(e) => setAddress1(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
    <div class="form-group number">
        <label>Country</label>
        <div style={{ position: 'relative' }}>
            <Select
                placeholder="Select country"
                value={selectedCountry}
                style={{ width: '100%' ,height:"55px"}}
                onChange={(value) => handleCountryClick(value)}
            >
                {countryList.map((country) => (
                <Option key={country.pincodeid} value={country.country}>
                  {country.country}
                </Option>
              ))}
            </Select>
        </div>
    </div>
</div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>State</label>
                                <div style={{ position: 'relative' }}> 
                                <Select
                  className="form-control"
                  name="state"
                  required
                  value={selectedState}
                  onChange={(value) => handleStateClick(value)}
                  style={{ width: '100%' }}
                  mode="default" 
                >
                  {stateList.map((stateItem) => (
                    <Option key={stateItem.pincodeid} value={stateItem.state}>
                      {stateItem.state}
                    </Option>
                  ))}
                </Select>
                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>City</label>
                                <input type="text" name="text" class="form-control" placeholder="Place" value={city} onChange={(e) => setCity(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Area</label>
                                <input type="text" name="text" class="form-control" placeholder="Place" value={area} onChange={(e) => setArea(e.target.value)}/>
                        </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Pincode</label>
                                <Select
  className="form-control"
  name="zip"
  required
  value={pincode}
  onChange={(value) => setPincode(value)}
>
  {pincodeList.map((zipItem) => (
    <Option key={zipItem.pincodeid} value={zipItem.pincode}>
      {zipItem.pincode}
    </Option>
  ))}
</Select>
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Native</label>
                                <label class="custom-select-select"/>
                                    <select  name='options' value={nativee} onChange={(e) => setNative(e.target.value)}>
                                      <option value="0">Native</option>
                                      <option> Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option>Amaravathipudur</option>
                                    <option>Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option>Athikkadu Thekkur</option>
                                    <option>Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option>Avanipatti</option>
                                    <option>Chockalingam Pudur</option>
                                    <option>Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option> Amaravathipudur</option>
                                    <option> Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option> Athikkadu Thekkur</option>
                                    <option> Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option> Avanipatti</option>
                                    <option> Chockalingam Pudur</option>
                                    <option> Chocknathapuram</option>
                                    <option>Devakottai</option>
                                    <option> Kaliarmangalam</option>
                                    <option> Kallal</option>
                                    <option> Kandavarayanpatti</option>
                                    <option> Kandanur</option>
                                    <option>Karaikudi</option>
                                    <option>Karunkulam</option>
                                    <option> Kilapungudi</option>
                                    <option> Kilasivalpatti</option>
                                    <option> P.Alagapuri</option>
                                    <option> Kollangudi Alagapuri</option>
                                    <option> Konapet</option>
                                    <option> Koppanapatti</option>
                                    <option> Kottamangalam</option>
                                    <option>Kottaiyur</option>
                                    <option>Kulipirai</option>
                                    <option>Kuruvikondanpatti</option>
                                    <option>Lakshmipuram</option>
                                    <option>Kothamangalam Lakshipuram</option>
                                    <option>Madaguppatti</option>
                                    <option>Chockalingapuram</option>
                                    <option>Mahibalanpatti</option>
                                    <option>Managiri</option>
                                    <option>Melasivapuri</option>
                                    <option>Mithalaipatti</option>
                                    <option>Nachundupatti</option>
                                    <option>Nachiapuram</option>
                                    <option>Natarajapuram</option>
                                    <option>Nemathanpatti</option>
                                    <option> Nerkupai</option>
                                    <option> Okkur</option>
                                    <option>Oyakondan Siruvayal</option>
                                    <option>Panageri</option>
                                    <option>Palavangudi</option>
                                    <option> Pallathur</option>
                                    <option> Panagudi</option>
                                    <option> Panayapatti</option>
                                    <option> Pariamaruthapatti</option>
                                    <option> Pattamangalam</option>
                                    <option> Pillaiyarpatti</option>
                                    <option>Ponnamaravathy</option>
                                    <option>Pulankurichi</option>
                                    <option>Pudupatti (Ponnamaravathy)</option>
                                    <option>Puduvayal</option>
                                    <option> Ramachandrapuram</option>
                                    <option> Rangiam</option>
                                    <option> Rajavaram</option>
                                    <option> Sakkandi</option>
                                    <option> Sembanur</option>
                                    <option>Sevvur</option>
                                    <option>Shanmuganathapuram (Aravayal)</option>
                                    <option> Siravayal</option>
                                    <option> Sirukudalpatti</option>
                                    <option> Solapuram</option>
                                    <option> Thanichavurani</option>
                                    <option> Thenipatti</option>
                                    <option>Ulagampatti</option>
                                    <option>Valayapatti</option>
                                    <option>Vegupatti</option>
                                    <option>Veendhampatti</option>
                                    <option> Vetriyur</option>
                                    <option>Virachalai</option>
                                    <option> Viramathi (Kilasivalpatti)</option>
                                    <option>V.Lakshipuram (Virachalai)</option>
                                    </select>
                            </div>
                        </div>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Kovil</label>
                                <label class="custom-select-select"/>
                                    <select  value={kovil} onChange={handleKovilChange}>
                                    <option value="Vairavan Kovil">Vairavan Kovil</option>
                                    <option value="Ilayathakudi"> Ilayathakudi</option>
                                    <option value="Mathur">Mathur</option>
                                    <option value="Iraniyur">Iraniyur</option>
                                    <option value="Pillaiyarpatti">Pillaiyarpatti</option>
                                    <option value="Soorakudi">Soorakudi</option>
                                    <option value="Iluppaikkudi">Iluppaikkudi</option>
                                    <option value="Nemam">Nemam</option>
                                    <option value="Velankudi">Velankudi</option>
                                    </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
  <label>Perivu</label>
  <label class="custom-select-select" />
  <select value={selectedPerivu} onChange={handlePerivuChange}>
    {perivu.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ))}
  </select>
</div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Mobile</label>
                                <input type="tel" name="text" className={`form-control ${
                      mobile.length !== 10 ? "custom-invalid" : ""
                    }`} placeholder="enter" value={mobile}   onChange={handleMobileChangeLogin}/>
                   { mobile.length < 10 && mobile.length > 0 && (
  <div className="error-message" style={{color:"red"}}>Please enter 10 digits</div>
)}
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label><span style={{ color: 'red' }}>*</span>Email</label>
                                <input type="email" name="email" class="form-control" placeholder="enter" value={email}  onChange={handleEmailChange}/>
                                {!isValidEmail && (
        <p style={{ color: 'red' }}>Please enter a valid email address</p>
      )}
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Password</label>
                                <input type="text" name="text" class="form-control" placeholder="enter" value={password} onChange={(e) => setPassword(e.target.value)}/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Proposer Name</label>
                                <input type="text" name="text" class="form-control" placeholder="enter" value={proposername} onChange={(e) => setProposername(e.target.value)}/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>secordorname</label>
                                <input type="text" name="text" class="form-control" placeholder="enter" value={secordorname} onChange={(e) => setSecordorname(e.target.value)}/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group subject">
                                <label>Year of Joining</label>
                                <input type="text" name="COMPANY NAME" class="form-control" placeholder="Enter" value={yearofjoining} onChange={(e) => setYearofjoining(e.target.value)}/>
                            </div>
                        </div>
                        {maritalstatus==='married' && (
                            <div>
                        <h3 class="main-title">Spouse  Details</h3>
                        <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Name</label>
                                <input type="text" name="text" class="form-control" placeholder="enter" value={spousename} onChange={(e) => setSpousename(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Native</label>
                                <label class="custom-select-select"/>
                                    <select  name='options' value={spousenativee} onChange={(e) => setSpousenativee(e.target.value)} >
                                      <option value="0">Native</option>
                                    <option> Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option>Amaravathipudur</option>
                                    <option>Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option>Athikkadu Thekkur</option>
                                    <option>Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option>Avanipatti</option>
                                    <option>Chockalingam Pudur</option>
                                    <option>Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option> Amaravathipudur</option>
                                    <option> Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option> Athikkadu Thekkur</option>
                                    <option> Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option> Avanipatti</option>
                                    <option> Chockalingam Pudur</option>
                                    <option> Chocknathapuram</option>
                                    <option>Devakottai</option>
                                    <option> Kaliarmangalam</option>
                                    <option> Kallal</option>
                                    <option> Kandavarayanpatti</option>
                                    <option> Kandanur</option>
                                    <option>Karaikudi</option>
                                    <option>Karunkulam</option>
                                    <option> Kilapungudi</option>
                                    <option> Kilasivalpatti</option>
                                    <option> P.Alagapuri</option>
                                    <option> Kollangudi Alagapuri</option>
                                    <option> Konapet</option>
                                    <option> Koppanapatti</option>
                                    <option> Kottamangalam</option>
                                    <option>Kottaiyur</option>
                                    <option>Kulipirai</option>
                                    <option>Kuruvikondanpatti</option>
                                    <option>Lakshmipuram</option>
                                    <option>Kothamangalam Lakshipuram</option>
                                    <option>Madaguppatti</option>
                                    <option>Chockalingapuram</option>
                                    <option>Mahibalanpatti</option>
                                    <option>Managiri</option>
                                    <option>Melasivapuri</option>
                                    <option>Mithalaipatti</option>
                                    <option>Nachundupatti</option>
                                    <option>Nachiapuram</option>
                                    <option>Natarajapuram</option>
                                    <option>Nemathanpatti</option>
                                    <option> Nerkupai</option>
                                    <option> Okkur</option>
                                    <option>Oyakondan Siruvayal</option>
                                    <option>Panageri</option>
                                    <option>Palavangudi</option>
                                    <option> Pallathur</option>
                                    <option> Panagudi</option>
                                    <option> Panayapatti</option>
                                    <option> Pariamaruthapatti</option>
                                    <option> Pattamangalam</option>
                                    <option> Pillaiyarpatti</option>
                                    <option>Ponnamaravathy</option>
                                    <option>Pulankurichi</option>
                                    <option>Pudupatti (Ponnamaravathy)</option>
                                    <option>Puduvayal</option>
                                    <option> Ramachandrapuram</option>
                                    <option> Rangiam</option>
                                    <option> Rajavaram</option>
                                    <option> Sakkandi</option>
                                    <option> Sembanur</option>
                                    <option>Sevvur</option>
                                    <option>Shanmuganathapuram (Aravayal)</option>
                                    <option> Siravayal</option>
                                    <option> Sirukudalpatti</option>
                                    <option> Solapuram</option>
                                    <option> Thanichavurani</option>
                                    <option> Thenipatti</option>
                                    <option>Ulagampatti</option>
                                    <option>Valayapatti</option>
                                    <option>Vegupatti</option>
                                    <option>Veendhampatti</option>
                                    <option> Vetriyur</option>
                                    <option>Virachalai</option>
                                    <option> Viramathi (Kilasivalpatti)</option>
                                    <option>V.Lakshipuram (Virachalai)</option>
                                
                                </select>
                                 
                            </div>
                        </div>
                        
                          <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Kovil</label>
                                <label class="custom-select-select"/>
                                    <select  name='options' value={spousekovil} onChange={handleKovilChangeforspouse}>
                                    <option value="Vairavan Kovil">Vairavan Kovil</option>
                                    <option value="Ilayathakudi"> Ilayathakudi</option>
                                    <option value="Mathur">Mathur</option>
                                    <option value="Iraniyur">Iraniyur</option>
                                    <option value="Pillaiyarpatti">Pillaiyarpatti</option>
                                    <option value="Soorakudi">Soorakudi</option>
                                    <option value="Iluppaikkudi">Iluppaikkudi</option>
                                    <option value="Nemam">Nemam</option>
                                    <option value="Velankudi">Velankudi</option>
                                    </select>
                                
                            </div>
                        </div> 

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Perivu</label>
                                <label class="custom-select-select"/>
                                <select value={selectedPerivuforspouse} onChange={handlePerivuChangeforspouse}>
            {spouseperivu.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
                            
                            </div>
                        </div> 
                        </div>
                        </div>
                        )}
                        <h3 class="main-title">Father  Details</h3>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Name</label>
                                <input type="text" name="text" class="form-control" placeholder="enter"value={fathername} onChange={(e) => setFathername(e.target.value)} />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Native</label>
                                <label class="custom-select-select"/>
                                    <select  name='options'>
                                      <option value="0">Native</option>
                                    <option> Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option>Amaravathipudur</option>
                                    <option>Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option>Athikkadu Thekkur</option>
                                    <option>Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option>Avanipatti</option>
                                    <option>Chockalingam Pudur</option>
                                    <option>Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option> Amaravathipudur</option>
                                    <option> Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option> Athikkadu Thekkur</option>
                                    <option> Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option> Avanipatti</option>
                                    <option> Chockalingam Pudur</option>
                                    <option> Chocknathapuram</option>
                                    <option>Devakottai</option>
                                    <option> Kaliarmangalam</option>
                                    <option> Kallal</option>
                                    <option> Kandavarayanpatti</option>
                                    <option> Kandanur</option>
                                    <option>Karaikudi</option>
                                    <option>Karunkulam</option>
                                    <option> Kilapungudi</option>
                                    <option> Kilasivalpatti</option>
                                    <option> P.Alagapuri</option>
                                    <option> Kollangudi Alagapuri</option>
                                    <option> Konapet</option>
                                    <option> Koppanapatti</option>
                                    <option> Kottamangalam</option>
                                    <option>Kottaiyur</option>
                                    <option>Kulipirai</option>
                                    <option>Kuruvikondanpatti</option>
                                    <option>Lakshmipuram</option>
                                    <option>Kothamangalam Lakshipuram</option>
                                    <option>Madaguppatti</option>
                                    <option>Chockalingapuram</option>
                                    <option>Mahibalanpatti</option>
                                    <option>Managiri</option>
                                    <option>Melasivapuri</option>
                                    <option>Mithalaipatti</option>
                                    <option>Nachundupatti</option>
                                    <option>Nachiapuram</option>
                                    <option>Natarajapuram</option>
                                    <option>Nemathanpatti</option>
                                    <option> Nerkupai</option>
                                    <option> Okkur</option>
                                    <option>Oyakondan Siruvayal</option>
                                    <option>Panageri</option>
                                    <option>Palavangudi</option>
                                    <option> Pallathur</option>
                                    <option> Panagudi</option>
                                    <option> Panayapatti</option>
                                    <option> Pariamaruthapatti</option>
                                    <option> Pattamangalam</option>
                                    <option> Pillaiyarpatti</option>
                                    <option>Ponnamaravathy</option>
                                    <option>Pulankurichi</option>
                                    <option>Pudupatti (Ponnamaravathy)</option>
                                    <option>Puduvayal</option>
                                    <option> Ramachandrapuram</option>
                                    <option> Rangiam</option>
                                    <option> Rajavaram</option>
                                    <option> Sakkandi</option>
                                    <option> Sembanur</option>
                                    <option>Sevvur</option>
                                    <option>Shanmuganathapuram (Aravayal)</option>
                                    <option> Siravayal</option>
                                    <option> Sirukudalpatti</option>
                                    <option> Solapuram</option>
                                    <option> Thanichavurani</option>
                                    <option> Thenipatti</option>
                                    <option>Ulagampatti</option>
                                    <option>Valayapatti</option>
                                    <option>Vegupatti</option>
                                    <option>Veendhampatti</option>
                                    <option> Vetriyur</option>
                                    <option>Virachalai</option>
                                    <option> Viramathi (Kilasivalpatti)</option>
                                    <option>V.Lakshipuram (Virachalai)</option>
                               
                                </select>
                            
                                 
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Kovil</label>
                                <label class="custom-select-select"/>
                                <select  value={fatherkovil} onChange={handleKovilChangeforfather}>
                                    <option value="Vairavan Kovil">Vairavan Kovil</option>
                                    <option value="Ilayathakudi"> Ilayathakudi</option>
                                    <option value="Mathur">Mathur</option>
                                    <option value="Iraniyur">Iraniyur</option>
                                    <option value="Pillaiyarpatti">Pillaiyarpatti</option>
                                    <option value="Soorakudi">Soorakudi</option>
                                    <option value="Iluppaikkudi">Iluppaikkudi</option>
                                    <option value="Nemam">Nemam</option>
                                    <option value="Velankudi">Velankudi</option>
                                    </select>
                                
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Perivu</label>
                                <label class="custom-select-select"/>
                                <select value={selectedPerivuforfather} onChange={handlePerivuChangeforfather}>
            {fatherperivu.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
                            
                            </div>
                        </div> 
                        <h3 class="main-title">Business  Details</h3>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Company Name</label>
                                <input type="text" name="text" class="form-control" placeholder="enter" value={companyname} onChange={(e) => setCompanyname(e.target.value)}/>
                         </div>
                        </div>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Category</label>
                                {/* <input type="text" name="text" class="form-control" placeholder="enter" value={companycategory} onChange={(e) => setCompanycategory(e.target.value)}/> */}
                                <Select
                                style={{width:"100%",height:"50px"}}
      placeholder="Select category"
      value={companycategory}
      onChange={(value) => setCompanycategory(value)}
    >
      {data.map(category => (
        <Option key={category.companycategoryid} value={category.companycategory}>
          {category.companycategory}
        </Option>
      ))}
    </Select>
                         </div>
                        </div>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Landline</label>
                                <input type="text" name="text" class="form-control" placeholder="enter" value={companylandline} onChange={(e) => setCompanylandline(e.target.value)}/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Mobile</label>
                                <input type="text" name="text" className={`form-control ${
                      companymobile.length !== 10 ? "custom-invalid" : ""
                    }`} placeholder="enter" value={companymobile} onChange={handleMobileChangeLoginforComapny}/>
                    {companymobile.length < 10 && companymobile.length > 0 && (
  <div className="error-message" style={{color:"red"}}>Please enter 10 digits</div>
)}
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Email</label>
                                <input type="email" name="email" class="form-control" placeholder="enter" value={companyemail}  onChange={handleEmailChangeforcompany}/>
                                {!isValidEmailforcomapny && (
        <p style={{ color: 'red' }}>Please enter a valid email address</p>
      )}
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Website Address</label>
                                <input type="text" name="text" class="form-control" placeholder="enter" value={companywebsite} onChange={(e) => setCompanywebsite(e.target.value)}/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Address 1</label>
                                <input type="text" name="text" class="form-control" placeholder="Place" value={companyaddress1} onChange={(e) => setCompanyaddress1(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Country</label>
                                <div style={{ position: 'relative' }}>
            <Select
                placeholder="Select country"
                value={selectedCountryforcompany}
                style={{ width: '100%' }}
                onChange={(value) => handleCountryClickforCompany(value)}
            >
                {companycountryList.map((country) => (
                <Option key={country.pincodeid} value={country.country}>
                  {country.country}
                </Option>
              ))}
            </Select>
        </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>State</label>
                                <div style={{ position: 'relative' }}> 
                                <Select
                  className="form-control"
                  name="state"
                  required
                  value={selectedStateforcompany}
                  onChange={(value) => handleStateClickforCompany(value)}
                  style={{ width: '100%' }}
                >
                  {companystateList.map((stateItem) => (
                    <Option key={stateItem.pincodeid} value={stateItem.state}>
                      {stateItem.state}
                    </Option>
                  ))}
                </Select>
                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>City</label>
                                <input type="text" name="text" class="form-control" placeholder="Place" value={companycity} onChange={(e) => setCompanycity(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Area</label>
                                <input type="text" name="text" class="form-control" placeholder="Place" value={companyarea} onChange={(e) => setCompanyarea(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Pincode</label>
                                <Select
  className="form-control"
  name="zip"
  required
  value={companypincode}
  onChange={(value) => setCompanypincode(value)}
>
  {companypincodeList.map((zipItem2) => (
    <Option key={zipItem2.pincodeid} value={zipItem2.pincode}>
      {zipItem2.pincode}
    </Option>
  ))}
</Select>
                            </div>
                        </div>
                        <h3 class="main-title">Refered by</h3>
                        <div class="col-lg-12 col-md-12 d-flex justify-content-center">
                            <div class="form-group number">
                                <label class="custom-select-select" />
<Select
      mode="multiple"
      style={{ width: '100%' ,marginTop:"25px",height:"50px",border:"1px solid #c7c7c7"}}
      placeholder="You should select two memebers"
      value={selectedMemberType}
      onChange={handleSelectChange}
    >
     {referedby.map((option) => (
  <Option key={option.clientid} value={option.name}>
    {option.name ? `${option.name} (${option.companyname || 'No Company'})` : 'No Name'}
  </Option>
))}
    </Select>
                        
                         </div>
                        </div> 
                        <div class="col-md-12">
                            <div class="send-btn text-center">
                            <button type="submit" class="btn-md button-theme" style={{width:"30%",marginLeft:"30%"}} >SUBMIT</button>
                                 
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
       
           
            
       
    </div>
</div>
</div>
<Footer/>
</div>
    )
}
export default Register;