import React, { useState } from "react";
import '../App.css';
import "../css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/default.css";
import "../css/slick.css";
import Header from "../../src/header/index";
import Footer from "../../src/footer/index";
import axios from "axios";
function Yesmember () {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [confirmpassword, setOldPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [showOtpFields, setShowOtpFields] = useState(false);
    const [resetMessage, setResetMessage] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const handleForgot = async () => {
        try {
            const response = await axios.put("http://ncc.riotinfomedia.com:12082/api/v1/client/emailotpclient", {
                email: email
            },
            {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

                setShowOtpFields(true);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };
    const handleVerifyOtp = () => {
        // Handle OTP verification logic here
        // You can use the entered OTP from the state (otp)
        // and make another API call to verify the OTP
    };

    const handleResetPassword = async () => {
        try {
            const response = await axios.put(
                "http://ncc.riotinfomedia.com:12082/api/v1/client/passwordchange",
                {
                    otp: otp,
                    newpassword: newpassword,
                    mobile  : email,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            setResetMessage(response.data.description);
            if (response.data.status==="Success") {
                window.location.href = "/Login"; // Adjust the path based on your routes
            }
            // Handle the response as needed (e.g., show success message)
            console.log("Password reset successful", response);

        } catch (error) {
            console.error("Error resetting password:", error);
            // Handle errors (e.g., show error message to the user)
        }
    };
    const handleNewPasswordChange = (e) => {
        const newPassword = e.target.value;
        setNewPassword(newPassword);
        setPasswordsMatch(newPassword === confirmpassword);
    };

    // Function to handle confirm password change and check if they match
    const handleConfirmPasswordChange = (e) => {
        const confirmNewPassword = e.target.value;
        setOldPassword(confirmNewPassword);
        setPasswordsMatch(newpassword === confirmNewPassword);
    };
return(
        <div>        
            <Header/>
        <div class="our-team content-area">
    <div class="container">
        
        <div class="main-title">
            <h1>Forgot Password</h1>
            <p></p>
        </div>
        <div class="row">
        <div class="col-lg-12">
        <div class="form-content-box" >
        <form onSubmit={(e) => e.preventDefault()} >
        <div class="form-group">
                            <input  type="tel" name="text" class="input-text" placeholder="Email" value={email}
                    onChange={(e) => setEmail(e.target.value)}/>   </div>
                     {showOtpFields ? (
                                        <>
                                            <div class="form-group">
                                                <input
                                                    type="text"
                                                    name="otp"
                                                    class="input-text"
                                                    placeholder="Enter OTP"
                                                    value={otp}
                                                    onChange={(e) => setOtp(e.target.value)}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <input
                                                    type="password"
                                                    name="newPassword"
                                                    class="input-text"
                                                    placeholder="Enter New Password"
                                                    value={newpassword}
                                                    onChange={handleNewPasswordChange}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <input
                                                    type="password"
                                                    name="confirmpassword"
                                                    class="input-text"
                                                    placeholder="Enter Confirm Password"
                                                    value={confirmpassword}
                                                    onChange={handleConfirmPasswordChange}
                                                />
                                                {!passwordsMatch && (
                    <div className="password-mismatch-error">Passwords do not match.</div>
                )}
                                            </div>
                                            
                                            <div class="form-group mb-0">
                                                <button
                                                    type="button"
                                                    class="btn-md button-theme btn-block"
                                                    onClick={handleResetPassword}
                                                    style={{ width: "50%", marginLeft: "20%" }}
                                                >
                                                    Reset Password
                                                </button>
                                            </div>
                                            {resetMessage && (
                <div className="reset-message">{resetMessage}</div>
            )}
                                        </>
                                    ) : (
                                        <div class="form-group mb-0">
                                            <button
                                                type="button"
                                                class="btn-md button-theme btn-block"
                                                onClick={handleForgot}
                                                style={{ width: "50%", marginLeft: "20%" }}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    )}
            </form>
        </div>
        </div>
        </div>
    </div>
</div>
<Footer/>
</div>

    )
}
export default Yesmember;